<template>
  <div class="total-card" v-if="user">
    <div class="total-card-content" :style="`--color:${user.color ? user.color : '#ffcfcf'};--picture:url(${user.picture})`">
      <div class="total-card-left">
        <div class="total-card-icon" :style="`--iconSize:${card.iconSize || '200%'}`">
          <figure class="figure">
            <iconic v-if="!user.picture" :name="user.icon || 'lock1a'" />
          </figure>
        </div>
        <div class="total-card-info">
          <div class="total-card-info-content">
            <div class="total-card-info-label">{{ user.user.name }}</div>
            <div class="total-card-info-desc">{{ card.desc }}</div>
          </div>
        </div>
      </div>
      <div class="total-card-right">
        <div>
          <span class="sign">{{ card.sign }}</span>
          <span class="mount">{{ card.num }}</span>
        </div>
        <div>
          <span class="mount_label">{{ card.numLabel }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["card", "user"],
};
</script>
